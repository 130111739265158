<template>
  <div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-select2"
      title="Vendor Details"
      centered
      ok-only
      size="lg"
    >
      <div v-if="loading == true">
        <b-spinner
          variant="primary"
          type="grow"
          style="margin-left: 350px"
          class="p-2"
          label="Spinning"
        ></b-spinner>
      </div>
      <div class="container mt-2">
        <div class="container">
          <!-- <b-card-img  :src="myObj.logo" v-if="myObj.logo !== ''" alt="Image" class="rounded-1" height="250px"></b-card-img>    -->
          <b-card v-if="loading == false">
            <div class="row">
              <div class="col-md-3">
                <div class="images" v-viewer>
                  <div class="profile-img pb-1">
                    <b-img
                      v-for="src in logo"
                      :key="src"
                      :src="src"
                      style="height: 100px"
                      rounded
                      fluid
                      alt="profile photo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <h4 class="mt-2">{{ myObj.business }}</h4>
                <div class="row">
                  <div class="col-md-12">
                    <p>{{ myObj.aboutUs }}</p>
                  </div>
                </div>
              </div>
            </div>

            <b-row>
              <b-col>
                <h4 class="mb-0">Details</h4>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <h6>Name</h6>
                <p>{{ this.myObj.firstName + ' ' + this.myObj.lastName }}</p>
              </b-col>
              <b-col>
                <h6>Email</h6>
                <p>{{ this.myObj.email }}</p>
              </b-col>
              <b-col>
                <h6>Contact</h6>
                <p>{{ this.myObj.phoneNo }}</p>
              </b-col>
              <b-col>
                <h6>Service</h6>
                <p>{{ this.myObj.service }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card-text>
                  <h5>Location</h5>
                  <p>{{ this.myObj.address + ' ' + this.myObj.city }}</p>
                </b-card-text>
              </b-col>
            </b-row>
             <b-row class="mb-1">
              <b-col cols="4">
                <b-card-text>
                  <h5>Deliverd by</h5>
                
          <v-select
            v-model="delivery"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="deliverdBy"
            :clearable="false"
            :disabled="dataloading"
            placeholder="Select delivered by."
          />
      
                </b-card-text>
              </b-col>
                <b-col style="margin-top:25px;" cols="4">
               <b-button variant="primary" @click="UpdateDelivery(myObj.id,delivery)" >
                 Update
               </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <h4 class="mb-0">Preferences</h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <b-card-text>
                  <p>{{ this.myObj.preferences }}</p>
                </b-card-text>
              </b-col>
            </b-row>
            <div
              v-if="
                this.myObj.checkService !== '' ||
                this.myObj.checkService !== null
              "
            >
              <b-row>
                <b-col>
                  <h4 class="mb-0">Services</h4>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <b-card-text>
                    <p>{{ this.myObj.checkServices }}</p>
                  </b-card-text>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col>
                <h4 class="mb-0">Documents</h4>
               
              </b-col>
            </b-row>

            <b-row class="mt-2">
                   <b-col cols="12">
                  <gallery
                    :images="this.myObj.VendorDocs"
                    :index="index"
                    @close="index = null"
                  ></gallery>
                 
                 
                    <!-- :style="{
                      backgroundImage: 'url(' + image + ')',
                      width: '150px',
                      height: '110px',
                    }" class="image rounded-1" -->
                    <b-row>
                      <b-col cols="*" style="margin-inline:2px;" v-for="(image, imageIndex) in this.myObj.VendorDocs"
                    :key="imageIndex">
                   <b-card-img
                     @click="index = imageIndex"
                      :src="image"
                      alt="Image"
                      class="rounded-1"
                      style=" height:140px; width:140px;"
                      
                    ></b-card-img>
                      </b-col>
                    </b-row>
                </b-col>
              <b-col>
                <!-- <b-table :items="docs" :fields="docfields" responsive striped>
                  <template #cell(#)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(path)="data">
                    <a :href="data.item.path" @click="Myfuncc(data.item.path)" >
                      {{ data.item.type }}</a
                    >
                     <img :src="data.item.path" alt="">
                  </template>
                </b-table> -->
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
      @ok="Filter()"
      title="Filters"
    >
      <b-form>
        <b-form-group>
          <h5>Select date range</h5>
          <div>
            <flat-pickr
              v-model="date.range"
              class="form-control"
              :config="{ allowInput: true, mode: 'range' }"
            ></flat-pickr>
          </div>
        </b-form-group>
        <br />
        <b-form-group>
          <h5>Select filter</h5>
          <v-select
            v-model="filterData"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productfilterOptions"
            label="text"
            :clearable="false"
            placeholder="Select Filter"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-card no-body class="mb-0" cols="12" md="12" sm="12">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <!-- <div style="padding-right:15px">
                <b-button
                  v-b-modal.modal-login
                  variant="primary"
                >
                  <span class="text-nowrap">Filters</span>
                </b-button>
              </div> -->
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table :items="filterVendors" :fields="fields" responsive striped>
          <template #cell(logo)="data">
            <b-avatar class="mr-1" :src="data.value" />
          </template>

          <template #cell(status)="data">
            <b-badge pill :variant="data.value.variant">
              {{ data.value.status }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="Status(data.item.userId, 'Rejected')"
              v-if="data.item.status.status == 'Pending'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon"
              style="margin-left: 3px"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Reject"
            >
              <feather-icon icon="XIcon" />
            </b-button>

            <b-button
              @click="Status(data.item.userId, 'Approved')"
              v-if="
                data.item.status.status == 'Pending' ||
                data.item.status.status == 'Rejected' ||
                data.item.status.status == 'Suspended'
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon"
              style="margin-left: 3px"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Approve"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
            <b-button
              @click="Status(data.item.userId, 'Suspended')"
              v-if="data.item.status.status == 'Approved'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              style="margin-left: 3px"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Suspend"
            >
              <feather-icon icon="EyeOffIcon" />
            </b-button>
            <b-button
              v-b-modal.modal-select2
              @click="openDetails(data.item)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="btn-icon"
              style="margin-left: 3px"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Details"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BSpinner,
  BCardImg,
  
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';

import { avatarText } from '@core/utils/filter';

import flatPickr from 'vue-flatpickr-component';
import Vue from 'vue';
import { BFormSelect } from 'bootstrap-vue';

import VueGallery from 'vue-gallery';
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
Vue.use(VueViewer);
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
BCardImg,
    BImg,

    BAvatar,

    BBadge,
    gallery: VueGallery,
    BPagination,
    vSelect,
    flatPickr,
  },

  props: {},
  computed: {
    filterVendors: function () {
      return this.items.filter((pro) => {
        return (
          pro.business.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.city.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },

  created() {
    this.LoadData();
  },
  data() {

    return {
    delivery:'',
    deliverdBy:['Quiqup','It self'],
    myimages:[],
    token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic',
      loading: false,
      suspended: '',
      filterData: '',
      date: {
        range: '',
      },
      searchQuery: '',
      logoindex: null,
      logo: [],
      index: null,

      cover: '',
      docs: [],
      partners: [],
      docfields: ['#', { label: 'Link', key: 'path' }],
      fields: [
        { key: 'logo', label: 'logo' },
        { label: 'Name', key: 'business' },
        { label: 'City', key: 'city' },
        { label: 'preferences', key: 'preferences' },
        { label: 'Status', key: 'status' },
        { label: 'Actions', key: 'actions' },
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        id:0,
        firstName: '',
        lastName: '',
        business: '',
        logo: '',
        email: '',
        phoneNo: '',
        status: '',
        aboutUs: '',
        address: '',
        city: '',
        service: '',
        preferences: '',
        checkServices: '',
        VendorDocs:[],
      },
      detailObj: {},
    };
  },
  methods: {
    Myfuncc(item)
    {
       console.log(item);
      window.open("data:image/png;base64,"+item, "_blank");
   
  //  window.open(item, item);
    },  UpdateDelivery(id, delivery) {
    // return console.log(id,delivery);
      var axios = require('axios');

      var config = {
        method: 'put',
        url: 'https://brandaway.fastech.pk/api/Vendors/UpdateDeliver/'+ id+ '/' + delivery,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.status == 'success') {
            this.$bvModal.hide('modal-select2');
            this.LoadData();
            this.$bvToast.toast('Delivery type updated.', {
              title: 'Success!',
              variant: 'success',
              toaster: 'b-toaster-bottom-center',
            });
          }
          console.log(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    Status(id, status) {
      var Obj = {
        id: id,
        status: status,
      };
      var axios = require('axios');

      var config = {
        method: 'put',
        url: 'https://brandaway.fastech.pk/api/Vendors/UpdateStatus',
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
        data: Obj,
      };

      axios(config)
        .then((response) => {
          if (response.data.status == 'success') {
            this.LoadData();
            this.$bvToast.toast('Status updated.', {
              title: 'Success!',
              variant: 'success',
              toaster: 'b-toaster-bottom-center',
            });
          }
          console.log(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    show() {
      this.$viewerApi({
        images: this.images,
      });
    },
    Filter() {
      // console.log("Osama");
      console.log(this.date.range, this.filterData.value);
      var split = this.date.range.split(' ');
      var from = split[0];
      var to = split[2];

      console.log(from);
    },
    ApproveVendor(id) {
      console.log(id);
      Swal.fire({
        title: 'Do you want to approve that Bakery?',
        text: "You won't be able to revert this!",
        icon: 'question',
        iconColor: '#FB595f',
        confirmButtonText: `Accept`,
        confirmButtonColor: '#FB595f',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/vendors/vendorStatus/?id=' +
              id +
              '&status=true',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                this.suspended = true;
                Swal.fire('Accepted!', '', 'success').then((res) => {
                  this.$bvModal.hide('modal-select2');
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    SuspendVendor(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, suspend it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/vendors/vendorStatus/?id=' +
              id +
              '&status=suspended',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Suspended!',
                  'Vendor has been suspended.',
                  'success',
                ).then((res) => {
                  this.$bvModal.hide('modal-select2');
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    DeclineVendor(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deny it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/vendors/vendorStatus/?id=' +
              id +
              '&status=false',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Denied!',
                  'Your order has been denied.',
                  'success',
                ).then((res) => {
                  this.$bvModal.hide('modal-select2');
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDetails(item) {
      this.myObj.id = item.id;
      this.myObj.firstName = item.firstName;
      this.myObj.lastName = item.lastName;
      this.myObj.business = item.business;
      this.myObj.email = item.email;
      this.myObj.phoneNo = item.phoneNo;
      this.myObj.logo = item.logo;
      this.myObj.status = item.status.status;
      this.myObj.aboutUs = item.aboutUs;
      this.myObj.address = item.address;
      this.myObj.city = item.city;
      this.myObj.service = item.service;
      this.myObj.preferences = item.preferences;
      this.myObj.checkServices = item.checkServices;
      this.myObj.VendorDocs = item.vendorDocs;
      this.delivery = item.delivery;
      this.logo = [];
      this.logo.push(this.myObj.logo);
      console.log(this.myObj);
    },
    AcceptOrder(id) {
      Swal.fire({
        title: 'Do you want to accept the order?',
        text: "You won't be able to revert this!",
        icon: 'question',
        iconColor: '#FB595f',
        confirmButtonText: `Accept`,
        confirmButtonColor: '#FB595f',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=true',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire('Accepted!', '', 'success').then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    DenyOrder(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deny it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=false',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Denied!',
                  'Your order has been denied.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    LoadData() {
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://brandaway.fastech.pk/api/Vendors/GetAllVendors',
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
         console.log(response.data);
          this.items = [];
          response.data.data.forEach((elem) => {
            if (elem.status == 'Pending') {
              elem.status = { status: elem.status, variant: 'secondary' };
              this.items.push(elem);
            } else if (elem.status == 'Approved') {
              elem.status = { status: elem.status, variant: 'success' };
              this.items.push(elem);
            } else if (elem.status == 'Suspended') {
              elem.status = { status: elem.status, variant: 'warning' };
              this.items.push(elem);
            } else if (elem.status == 'Rejected') {
              elem.status = { status: elem.status, variant: 'danger' };
              this.items.push(elem);
            }
          });

          console.log(this.items);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

@import '@core/scss/vue/pages/page-profile.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
